import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'page-portfolio' }
  },
  {
    path: '/login',
    name: 'page-login',
    component: () => import('@/views/pages/auth/Login.vue')
  },  
  {
    path: '/forgot',
    name: 'page-forgot',
    component: () => import('@/views/pages/auth/Forgot.vue')
  },  
  {
    path: '/forgotreset',
    name: 'page-forgotreset',
    component: () => import('@/views/pages/auth/OTPPassword.vue')
  },    
  {
    path: '/portfolio',
    name: 'page-portfolio',
    component: () => import('@/views/pages/portfolio/View.vue')
  },    
  {
    path: '/analytics',
    name: 'page--analytics',
    component: () => import('@/views/pages/portfolio/Analytics.vue')
  },   
  {
    path: '/portfoliodetail/:id/:orderId',
    name: 'page-portfolio-detail',
    component: () => import('@/views/pages/portfolio/Detail.vue')
  },    
  
  {
      path: '*',
      redirect: '/error-404'
  }  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/error-404','/login','/terms','/privacy','/forgot','/forgotreset'];
  const authRequired = !publicPages.includes(to.path);

  const loggedIn = store.state.token;


  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
})


export default router
