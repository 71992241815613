import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMaterial from 'vue-material'

import './index.css'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'


Vue.use(VueMaterial)

// change multiple options
Vue.material = {
  ...Vue.material,
  locale: {
    ...Vue.material.locale,
    dateFormat: 'dd/MM/yyyy',
    firstDayOfAWeek: 1
  }
}

import Notifications from 'vue-notification'
Vue.use(Notifications)

Vue.config.productionTip = false;

// Vuex Store
import store from './store'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
