import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})


export default new Vuex.Store({
    plugins: [vuexLocal.plugin],
    state: {
        token: null,
        user: null,
        portfolio: null,
    },
    mutations: {
      login(state, userData) {
        state.token = userData.token
      },
      storeUserData(state, userData) {
        state.user = userData.userdata
      },
      storePortfolioData(state, portfolioData) {
        state.portfolio = portfolioData.portfoliodata
      },

    },
    actions: {
      login({commit}, authData) {
        commit('login', {
              token: authData.token,
          })
      },
      storeUserData({commit}, userData) {
        commit('storeUserData', {
              userdata: userData.userdata,
          })
      },
      storePortfolioData({commit}, portfolioData) {
        commit('storePortfolioData', {
              portfoliodata: portfolioData.portfoliodata,
          })
      },
}
})